import React, { useState, useEffect, useRef } from 'react'

import * as styles from './styles.module.scss';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from 'react-youtube';

import PlayButton from '../../../images/icons/playbutton.svg'

const Video = (props) => {

    const video = useRef();
    useEffect(() => {


    }, []);

    let opts = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            showinfo: 0,
            modestbranding: 1,
            rel: 0,
        },
    };

    if (props.type === 'fullscreen') {

        opts.playerVars.autoplay = 1;

        if (props.show) {
            return <>
                <div className={styles.popup} onClick={props.hide}>
                    <YouTube
                        opts={opts}
                        videoId={props.video_id}
                    />
                </div>
            </>
        }

        return null;

    } else {
        return <YouTube
            opts={opts}
            containerClassName={styles.video}
            className={styles.video}
            videoId={props.video_id}
        />
    }



}


export default Video;