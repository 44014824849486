import React, { useState } from 'react';
import * as styles from "./styles.module.scss"
import * as buttonStyles from "../Button/styles.module.scss"
import Button from "../Button";

import UploadIcon from '../../../images/upload.svg';

const ImageUpload = ({ style, updateFileParent }) => {

    const [fileName, setFileName] = useState(null);

    const updateFile = (e) => {

        updateFileParent(e)

        setFileName(e.target.files[0].name);
        console.log(e.target.files[0]);


    }

    return (
        <label>
            <div style={style} className={`uploadContainer ${styles.container}`} style={{ paddingLeft: 0 }}>
                <div className={`${buttonStyles.button} ${buttonStyles.primary} ${styles.uploadButton}`}>
                    <UploadIcon
                        className={`${styles.uploadIcon} `}
                    />
                </div>
                {fileName || "Lade ein Foto hoch"}
                
                <input
                    type="file"
                    onChange={e => updateFile(e)}
                    hidden
                />

            </div>
        </label>
    );
};

export default ImageUpload;
