import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import Hero from '../Hero';
import axios from 'axios';
import SingleImage from '../SingleImage';


const News = ({ type, offset, amount }) => {

    const [posts, setPosts] = useState([]);

    useEffect(() => {

        if (type === 'list') {

        } else {
            axios.get('https://linzpartei.at/wp-json/wp/v2/posts/?per_page=1&_embed').then(res => {
                setPosts(res.data);
            }).catch(err => {
            })
        }
    }, [])


    if (type === 'list') {

    } else {


        if (posts.length < 1) {
            return null;
        }

        return <div className="container">
            <div className="col5">
                <SingleImage url={posts[0]._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url} />
            </div>
            <div className="col2"></div>
            <div className="col5">
                <h1>{posts[0].title.rendered}</h1>
            </div>
        </div>

    }

}

export default News;
