import React from 'react'
import { Link } from 'gatsby';

export default class Spacer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {



    }

    get_id = () => {
        return 'spacer'
    }

    render() {

        return (
            <div className="spacer" style={this.props.style}></div>
        );

    }

}
