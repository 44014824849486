import React, { useState, useEffect, useRef } from 'react'
import * as styles from './styles.module.scss';
import Video from '../Video';

const SingleImage = (props) => {

    const [showVideo, setShowVideo] = useState(false);

    return <div className={styles.container}>
        <img className={styles.single_image} src={props.url} />
        {
            props.video_id &&
            <><Video
                video_id={props.video_id}
                type="fullscreen"
                show={showVideo}
                hide={() => setShowVideo(false)}
            />
                <div className={styles.videoButton} onClick={() => setShowVideo(true)}>Video ansehen</div>
            </>
        }
    </div>

}

export default SingleImage;
