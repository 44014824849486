import React from 'react'
import * as styles from './styles.module.scss'



const Hero = ({ image, headline_1, headline_2, subheadline }) => {

    return (
        <div className={styles.container} style={{ backgroundImage: `url(${image})` }}>
            <div className={styles.content}>
                <div className={`${styles.headline} ${styles.headlineOne}`}>{headline_1}</div>
                <div className={`${styles.headline} ${styles.headlineTwo}`}>{headline_2}</div>
                <div className={`${styles.subheadline}`}>{subheadline}</div>
            </div>
        </div>
    );

}

export default Hero;