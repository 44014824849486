import React from 'react'
import { Link } from 'gatsby'; 
import ReactHtmlParser from 'react-html-parser';





export default class Text extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {
    }

    get_id = () => {
        return 'text'
    }

    render() {

        return <div style={this.props.style || {}}>
            {ReactHtmlParser(this.props.text)}
        </div>

    }

}
