import React, { useEffect, useRef } from "react"
// import "./styles.scss"
import { renderElements } from "../";

const Row = props => {

    const row = useRef(null);

    console.log('PROPS ROW', props)

    const renderColumns = () => {

        let output = [];

        for (var i = 1; i <= parseInt(props.columns); i++) {

            output.push(<div key={i} className={"col" + props.column_sizes['col' + (i)]}>
                <div>
                    {renderElements(props.children[(i - 1)].children)}
                </div>
            </div>);

        }

        return output;

    }

    let id = '';
    let classes = props.classes;

    if(props.sidemenu_id !== ''){
        id = props.sidemenu_id;
    }

    return (
        <div id={id} ref={row} style={{ ...props.style }} className={"container row " + classes.join(' ')}>
            {renderColumns()}
        </div>
    )


}


export default Row;