import { Link } from "gatsby";
import React, { useEffect, useRef } from "react"
import ReactHtmlParser from 'react-html-parser';

import Button from '../Button';
import * as styles from './styles.module.scss'

const TextImageItem = ({ image_url, headline, text, destination, button_title }) => {

    let element = (
        <><div
            className={styles.image}
            style={{
                backgroundImage:
                    "url(" + image_url + ")",
            }}
        ></div>
            <div
                className={styles.content}
            >
                <h3>{ReactHtmlParser(headline)}</h3>
                {ReactHtmlParser(text)}

                {destination !== '' && <Button destination={destination} type="line" title="mehr erfahren" style={{ bottom: 20, position: 'absolute' }} />}
            </div></>

    );


    if (destination !== '') {
        element = <Link className={`nostyle ${styles.itemContainer} `} to={destination}>{element}</Link>
    }else{
        element = <div className={styles.itemContainer}>{element}</div>
    }

    return element;


}


export default TextImageItem;