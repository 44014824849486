import React from 'react';
/**
 * Page elements like Text, Headline, Slider, etc.
 */

import Button from "./Button";
import Row from "./Row";
import Text from "./Text";
import Headline from "./Headline";
import SingleImage from "./SingleImage";
// import Header from "./Header";
import Spacer from "./Spacer";
import ContactForm from "./ContactForm";
import Video from "./Video";
import TextImageContainer from './TextImageContainer';
import TestimonialContainer from './TestimonialGrid';
import Hero from './Hero'
import StartHero from './StartHero';
import Accordion from './Accordion'
import News from './News';
import GuestForm from './ContactForm/guestForm';
import SmallContactForm from './ContactForm/small';


export const Elements = {
    Button: Button,
    Row: Row,
    Headline: Headline,
    Text: Text,
    // Header: Header,
    Spacer: Spacer,
    ContactForm: ContactForm,
    Video: Video,
    Hero: Hero
};

export const renderElements = (content_raw) => {

    let content = content_raw;
    console.log("CONTENT", content_raw);

    if (typeof content_raw !== 'object') {

        try {
            content = JSON.parse(content_raw);
        } catch {
            return 'NO OBJECT FOUND'
        }

    }

    if (content === null) {
        return 'NO OBJECT FOUND'
    }

    return content.map((element, key) => {

        switch (element.id) {
            case 'row':
                return <Row
                    key={key}
                    {...element}
                />

            case 'button':
                return <Button
                    key={key}
                    {...element}
                />

            case 'headline':
                return <Headline
                    key={key}
                    {...element}
                />

            case 'text':
                return <Text
                    key={key}
                    {...element}
                />

            case 'single_image':
                return <SingleImage
                    key={key}
                    {...element}
                />

            // case 'header':
            //     return <Header
            // key={key}
            //         {...element}
            //     />

            case 'spacer':
                return <Spacer
                    key={key}
                    {...element}
                />

            case 'contact_form':
                return <ContactForm
                    key={key}
                    {...element}
                />

            case 'small_contact_form':
                return <SmallContactForm
                    key={key}
                    {...element}
                />

            case 'guest_form':
                return <GuestForm
                    key={key}
                    {...element}
                />

            case 'video':
                return <Video
                    key={key}
                    {...element}
                />

            case 'text_image_container':
                return <TextImageContainer
                    key={key}
                    {...element}
                />

            case 'hero':
                return <Hero
                    key={key}
                    {...element}
                />

            case 'start-hero':
                return <StartHero
                    key={key}
                    {...element}
                />

            case 'testimonial_grid':
                return <TestimonialContainer
                    key={key}
                    {...element}
                />

            case 'accordion':
                return <Accordion
                    key={key}
                    {...element}
                />

            case 'news':
                return <News
                    key={key}
                    {...element}
                />

            default:
                return <div key={key}>{element.id}</div>
        }

    });
}