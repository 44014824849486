import { Link } from "gatsby";
import React, { useEffect, useRef } from "react"
import ReactHtmlParser from 'react-html-parser';

import Headline from '../Headline';

import * as styles from './styles.module.scss'

const TestimonialItem = ({ featuredImage, content, title, job, imageurl }) => {

    console.log("featuredImage", featuredImage)
    console.log("imageurl", imageurl)

    let image = 'https://spectory.at/spoe-urfahr-nord/wp-content/uploads/2021/08/avatar_website.jpg';

    console.log("featuredImage", featuredImage)
    
    if(featuredImage !== undefined && featuredImage !== null &&featuredImage.node !== null && featuredImage.node.sourceUrl !== undefined){
        image = featuredImage.node.sourceUrl;
    }
    if( imageurl !== undefined){
        image =  imageurl;
    }

    return (
        <div className={styles.itemContainer}>
            {image !== '' &&
                <div
                    className={styles.image}
                    style={{
                        backgroundImage:
                            "url(" + image + ")",
                    }}
                ></div>
            }
            <div
                className={styles.content}
            >
                <Headline
                    text={ReactHtmlParser(title)}
                    subheadline={ReactHtmlParser(job)}
                    type={'h2'}
                />
                {ReactHtmlParser(content)}
            </div>
        </div>
    )


}


export default TestimonialItem;