import React, { useState } from 'react';
import * as styles from "./styles.module.scss"
import { t, tStatic } from "../../../services/multilingual/translate"
import { useForm } from 'react-hook-form';
import { API_BASE } from '../../../spectory-config';
import axios from 'axios';
import { TextField, Button as MaterialUiButton, Checkbox, FormControlLabel } from "@material-ui/core";
import Button from "../Button";

import SuccessIcon from '../../../images/check.svg';

const SmallContactForm = ({ style, lang }) => {

    const [gdpr, setGdpr] = useState(false);

    let eStyles = {}

    if (style.position == "right") {
        eStyles.float = "right";
    }

    const [loading, setLoading] = useState(false);
    const [sendingSuccess, setSendingSuccess] = useState(false);
    const [error, setError] = useState(false);

    const { register, handleSubmit, reset, watch } = useForm();

    const onSubmit = (data) => {

        console.log('DATA', data)
        console.log('DATA GDPR', gdpr)

        if (!gdpr) {
            return false;
        }

        var formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("message", data.message);
        
        setError(false);

        axios.post(API_BASE+'/forms/contact', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            console.log(res);
            setLoading(false);
            setSendingSuccess(true);
            reset({
                name: '',
                email: '',
                message: ''
            });
            setTimeout(() => {
                setSendingSuccess(false);
            }, 5000);

            
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setError(true);

        })

        setLoading(true);
    }

    return (
        <div style={eStyles} className={`formContainer ${loading ? 'loading' : null}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container fluid" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 30 }}>
                    <div className="col6">
                        <TextField
                            required
                            {...register("email")}
                            label={'E-Mail'}
                            variant="filled"
                            id="filled-basic"
                            style={{ marginBottom: 30 }}
                        />
                    </div>
                    <div className="col6">
                        <TextField
                            required
                            {...register("name")}
                            label={'Name'}
                            variant="filled"
                            id="filled-basic"
                            style={{ marginBottom: 30 }}
                        />
                    </div>
                </div>
                <div className="container fluid" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 30 }}>
                    <div className="col12">
                        <TextField
                            required
                            multiline
                            {...register("message")}
                            label={'Nachricht'}
                            variant="filled"
                            id="filled-basic"
                            style={{ marginBottom: 30 }}
                        />
                        <FormControlLabel

                            style={{ marginBottom: 30 }}
                            control={
                                <Checkbox
                                    {...register("gdpr")}
                                    className={"checkedIcon_root"}
                                    // value={true}
                                    onChange={() => setGdpr(!gdpr)}
                                    disableRipple
                                    color="default"
                                    checkedIcon={
                                        <span className="checkedIcon checkedIcon_icon" />
                                    }
                                    icon={
                                        <span
                                            className={"checkedIcon_icon"}
                                        />
                                    }
                                    inputProps={{
                                        "aria-label": "decorative checkbox",
                                    }}
                                />
                            }
                            label={
                                "Ich akzeptiere, dass meine Daten online gespeichert werden."
                            }
                        />
                        <br/>
                        <Button type="submit" title="Nachricht senden" />

                    </div>
                </div>


                <div className="container fluid" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 30 }}>
                    <div className="col12">

                    </div>
                </div>
                <div className="container fluid" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 30 }}>
                    <div className="col12">
                    </div>
                </div>

            </form>
            {
                error && <div className={styles.error}>Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.</div>
            }
            {
                sendingSuccess && <div className={styles.success}><SuccessIcon />Vielen Dank! Wir haben deine Anmeldung erhalten und dir eine Bestätigungsmail geschickt.</div>
            }
        </div>
    );
};

export default SmallContactForm;
