import React, { useEffect, useRef } from "react"

import * as styles from './styles.module.scss';
import TestimonialItem from './item';
import { graphql, useStaticQuery } from "gatsby";

const TestimonialContainer = props => {

    const data = useStaticQuery(graphql`
    {
      allWpStimme {
        nodes {
          job
          title
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `)

    const renderItems = () => {
        return data.allWpStimme.nodes.map((item, key) => {

            if(props.amount > 0 && key >= props.amount)return null;
            
            return <TestimonialItem {...item} key={key} />;
        })
    }

    return (
        <div style={{ ...props.style }} className={styles.container}>
            {renderItems()}
        </div>
    )

}


export default TestimonialContainer;

