import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { renderElements } from "../components/elements/index"

import SEO from "../components/seo";


export const query = graphql`
  query($id: String!) {
    wpPage(
        id: { eq: $id }
        ) {
        id
        uri
      title
      renderContent
    }
  }
`

const Page = props => {
    console.log("props", props.data)

    // const lang = props.pageContext.lang.split('_')[0];

    if (props.data.wpPage === null) return null;

    return (
        <Layout
            contentObjects={JSON.parse(props.data.wpPage.renderContent)}
            path={props.pageContext.uri}
            hideMenu={props.data.wpPage.hideMenu}
        >
            <SEO
                title={props.data.wpPage.title}
                description="SPÖ Urfahr Nord"
                keywords={"SPÖ, Linz, Urfahr Nord, Linzpartei"}
                author="spectory"
            />
            {renderElements(props.data.wpPage.renderContent)}
        </Layout>
    )
}

export default Page