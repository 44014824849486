import React, { useEffect, useRef } from "react"

import * as styles from './styles.module.scss'
import TextImageItem from './item'
const TextImageContainer = props => {

    const renderItems = () => {
        return props.children.map((item, key) => {
            return <TextImageItem {...item} key={key} />;
        })
    }

    return (
        <div style={{ ...props.style }} className={styles.container}>
            {renderItems()}
        </div>
    )


}


export default TextImageContainer;