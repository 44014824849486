import React from 'react'
import * as styles from './styles.module.scss'
import { Link } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import Button from "../Button";
import Text from '../Text';

import Map from '../../../images/images/map.png';


const StartHero = ({ image, headline_1, headline_2, text, link }) => {

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <img src={Map} className={styles.map} />
            </div>
            <div className={styles.rightContainer}>
                <h1 className={`${styles.headline} ${styles.red}`}>{ReactHtmlParser(headline_1)}</h1>
                <h1 className={`${styles.headline}`}>{ReactHtmlParser(headline_2)}</h1>
                <p className={styles.text}>{ReactHtmlParser(text)}</p>
                <Button destination={link} title="mehr erfahren" type="line" />
            </div>
        </div>
    );

}

export default StartHero;