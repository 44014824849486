import React, { useState } from 'react';
import * as styles from "./styles.module.scss"
import { t, tStatic } from "../../../services/multilingual/translate"
import { useForm } from 'react-hook-form';
import { API_BASE } from '../../../spectory-config';
import axios from 'axios';
import { TextField, Button as MaterialUiButton, Checkbox, FormControlLabel } from "@material-ui/core";
import Button from "../Button";

import ImageUpload from './ImageUpload.js';

import ExampleImage from '../../../images/images/beispielbild.jpg'
import SuccessIcon from '../../../images/check.svg';

import UploadIcon from '../../../images/upload.svg';
import TestimonialItem from '../TestimonialGrid/item';

const JobApplicationForm = ({ style, lang }) => {

    const [gdpr, setGdpr] = useState(false);

    let eStyles = {}

    if (style.position == "right") {
        eStyles.float = "right";
    }

    const [loading, setLoading] = useState(false);
    const [sendingSuccess, setSendingSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [file, setFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    const { register, handleSubmit, reset, watch } = useForm();

    const onSubmit = (data) => {

        console.log('DATA', data)
        console.log('DATA GDPR', gdpr)

        if (!gdpr) {
            return false;
        }

        var formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("job", data.job);
        formData.append("file", file);
        formData.append("message", data.text);
        
        setError(false);

        axios.post(API_BASE+'/support_form', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            console.log(res);
            setLoading(false);
            setSendingSuccess(true);
            reset({
                name: '',
                email: '',
                job: '',
                message: '',
            });
            setFile('');
            setTimeout(() => {
                setSendingSuccess(false);
            }, 5000);

            
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setError(true);

        })

        setLoading(true);
    }

    const updateFile = (e) => {
        setFile(e.target.files[0]);

        var fr = new FileReader();
        fr.onload = function (e) { setImageSrc(this.result) };
        fr.readAsDataURL(e.target.files[0]);

    }

    return (
        <div style={eStyles} className={`formContainer ${loading ? 'loading' : null}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container fluid" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 30 }}>
                    <div className="col8">
                        <TextField
                            required
                            {...register("name")}
                            label={'Name'}
                            variant="filled"
                            id="filled-basic"
                            style={{ marginBottom: 30 }}
                        />
                        <TextField
                            {...register("job")}
                            label={'Beruf'}
                            variant="filled"
                            id="filled-basic"
                            style={{ marginBottom: 30 }}
                        />
                        <TextField
                            {...register("email")}
                            label={'E-Mail'}
                            variant="filled"
                            id="filled-basic"
                            style={{ marginBottom: 30 }}
                        />
                        <TextField
                            {...register("text")}
                            label={'Warum wird Julia eine gute Bürgermeisterin?'}
                            variant="filled"
                            multiline
                            id="filled-basic"
                            style={{ marginBottom: 30 }}
                        />
                        <ImageUpload
                            style={{ marginBottom: 30 }}
                            updateFileParent={e => updateFile(e)}
                        />

                        <FormControlLabel

                            style={{ marginBottom: 30 }}
                            control={
                                <Checkbox
                                    {...register("gdpr")}
                                    className={"checkedIcon_root"}
                                    // value={true}
                                    onChange={() => setGdpr(!gdpr)}
                                    disableRipple
                                    color="default"
                                    checkedIcon={
                                        <span className="checkedIcon checkedIcon_icon" />
                                    }
                                    icon={
                                        <span
                                            className={"checkedIcon_icon"}
                                        />
                                    }
                                    inputProps={{
                                        "aria-label": "decorative checkbox",
                                    }}
                                />
                            }
                            label={
                                "Ich akzeptiere, dass meine Daten online gespeichert werden und dass die SPÖ meine Angaben verändern und in ihrem Namen auf ihrer Website und in anderen Onlinemedien (Facebook, Instagram, etc.) veröffentlichen dürften."
                            }
                        />

                        <Button type="submit" title="Nachricht senden" />

                    </div>
                    <div className="col4">
                        <h2>Vorschau</h2><br />
                        <TestimonialItem
                            imageurl={imageSrc || ExampleImage}
                            title={watch('name') || 'Dein Name'}
                            job={watch('job') || 'Dein Beruf'}
                            content={watch('text') || 'Deine Nachricht'}
                        />
                    </div>
                </div>


                <div className="container fluid" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 30 }}>
                    <div className="col12">

                    </div>
                </div>
                <div className="container fluid" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 30 }}>
                    <div className="col12">
                    </div>
                </div>

            </form>
            {
                error && <div className={styles.error}>Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.</div>
            }
            {
                sendingSuccess && <div className={styles.success}><SuccessIcon />Vielen Dank! Deine Nachricht wurde an uns gesendet.</div>
            }
        </div>
    );
};

export default JobApplicationForm;
