import React, { useEffect, useRef, useState } from "react"
import { Accordion, Card } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';
import Button from '../Button';

import Arrow from "../../../images/icons/accordion_arrow.png";

import * as styles from './styles.module.scss'
import "./bootstrap.css";
const AccordionContainer = props => {

    const [currentItemKey, setCurrentItemKey] = useState(0);

    const renderItems = () => {
        return props.children.map((item, key) => {

            key = key + 1;

            return <Card className={`${styles.card} `}>
                <Accordion.Toggle
                    eventKey={key}
                    as={Card.Header}
                    className={styles.cardHeader}
                    onClick={() =>{
                        if(currentItemKey === key){
                            setCurrentItemKey(0);
                        }else{
                            setCurrentItemKey(key);
                        }
                    }
                        
                    }
                >
                    {ReactHtmlParser(item.title)}
                    <img
                        src={Arrow}
                        className={
                            currentItemKey == key
                                ? styles.show
                                : ""
                        }
                    />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={key}>
                    <Card.Body>
                        {ReactHtmlParser(item.text)}

                        {
                            item.destination !== null && item.destination !== '' && <Button type={"line"} destination={"item.destination"} title={"mehr erfahren"} />
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>;
        })
    }

    return (
        <Accordion style={{ ...props.style }} className={`${styles.accordion}`}>
            {renderItems()}
        </Accordion>
    )


}


export default AccordionContainer;